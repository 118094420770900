<template>
    <div class="Poll">

        <Card>

            <div
                v-if="isPollSaved"
                class="Poll__success">
                Thank you, the account manager will confirm your account soon
            </div>

            <template v-if="!isPollSaved">

                <div
                    class="Poll__questions">
                    <div v-for="question in questions" class="Poll__question">
                        <div class="Poll__question__title">
                            {{ question.question }}
                        </div>
                        <div class="Poll__options">

                        <!--                        <div class="Poll__option mb-20">
                                <div class="Poll__option__title">All</div>
                                <div class="Poll__option__switch">
                                    <FormSwitch
                                        :checked="true"
                                    ></FormSwitch>
                                </div>
                            </div>-->

                            <template v-for="option in question.options">
                                <div
                                    @click="clickOption(question.id, option.id, option.comment)"
                                    class="Poll__option">
                                    <div class="Poll__option__title">
                                        {{ option.option }}
                                    </div>
                                    <div class="Poll__option__switch">
                                        <FormSwitch
                                            :label="false"
                                            :checked="isOptionChecked(question.id, option.id)"
                                        ></FormSwitch>
                                    </div>
                                </div>

                                <div v-if="option.need_comment && isOptionChecked(question.id, option.id)">
                                    <FormTextarea
                                        v-model="option.comment"
                                        placeholder="Enter the message.."
                                    ></FormTextarea>
                                </div>
                            </template>

                        </div>
                    </div>
                </div>

                <Alert
                    v-if="isShowAlertAboutAnswers"
                    addclass="mb-20"
                    message="Please answer all questions"
                ></Alert>

                <div class="card__separator"></div>



                <div class="Poll__btns">
                    <button
                        @click="savePollAnswers"
                        class="wv-btn--text wv-btn--green">
                        Submit
                    </button>
                </div>
            </template>

        </Card>

    </div>
</template>

<script>
export default {
    name: 'Poll',
    props: [],
    data() {
        return {
            questions: [],
            answers: [],
            isPollSaved: false,
            isShowAlertAboutAnswers: false,
        };
    },
    computed: {},
    watch: {
        questions: {
            deep: true,
            handler: function(questions) {
                collect(questions)
                    .pluck('options')
                    .flatten(1)
                    .whereNotIn('comment', [''])
                    .all()
                    .forEach(option => {
                        this.updateCommentInAnswer(option)
                    })

            }
        }
    },
    methods: {
        clickOption(question_id, option_id, comment) {
            this.isShowAlertAboutAnswers = false

            if (this.isOptionChecked(question_id, option_id)) {
                this.uncheckOption(option_id);
            } else {
                this.answers = [
                    ...this.answers, {
                        question_id: question_id,
                        option_id: option_id,
                        comment: comment
                    }];
            }
        },
        updateCommentInAnswer(option) {
            this.answers = collect(this.answers)
                .map(answer => {
                    if (answer.option_id === option.id) answer.comment = option.comment
                    return answer
                })
                .all()
        },
        uncheckOption(option_id) {
            this.answers = collect(this.answers)
                .filter(answer => answer.option_id !== option_id)
                .all();
        },
        isOptionChecked(question_id, option_id) {
            return collect(this.answers)
                .where('question_id', question_id)
                .where('option_id', option_id)
                .count() > 0;
        },
        getPollData() {
            WV.Api.getPoll()
                .then(response => {
                    let poll = response[0];
                    this.questions = collect(poll.questions)
                        .map(question => {
                            question.options = collect(question.options)
                                .map(option => {
                                    option.comment = '';
                                    return option;
                                })
                                .all();
                            return question;
                        })
                        .all();
                });
        },
        isAllQuestionsAnswered() {
            let pass = true
            this.questions.forEach(question => {
                pass = pass ? collect(this.answers).where('question_id', question.id).count() > 0 : false
            })
            return pass
        },
        savePollAnswers() {
            if (this.isAllQuestionsAnswered()) {
                WV.Api.savePollAnswers(this.answers).then(response => {
                    this.isPollSaved = true
                    this.$store.dispatch('auth/getUser')
                })
            } else {
                this.isShowAlertAboutAnswers = true
            }

        },
    },
    beforeMount() {
        this.getPollData();
    },
    mounted() {
        if (this.$store.getters['auth/isUserPollPassed']) {
            this.isPollSaved = true;
        }
    },
};
</script>

<style lang="scss">
@import '../../../scss/utils/mixins.scss';
@import '../../../scss/utils/variables.scss';

.Poll {
    width: 100%;
    margin-top: 90px;
    @include break(md) {
        margin-top: 0;
    }
    &__questions {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        padding-bottom: 20px;
        flex-wrap: wrap;
    }
    &__question {
        width: 100%;
        margin-top: 40px;
        @include break(lg) {
            width: 30%;
            margin-top: 0;
        }
        &:first-child {
            margin-top: 0;
        }
        &__title {
            font-family: $font-primary;
            font-weight: bold;
            font-size: 18px;
            line-height: 22px;
            letter-spacing: 0.005em;
            margin-bottom: 30px;
        }
    }
    &__options {

    }
    &__option {
        border: 1px solid $color-day-line;
        border-radius: 8px;
        margin-bottom: 10px;
        min-height: 40px;
        padding: 0 0 0 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        font-family: $font-primary;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        cursor: pointer;
        &__switch {
            margin-right: -30px;
            transform: scale(.75);
        }
    }
    &__btns {
        margin-top: 30px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
    &__success {
        font-weight: bold;
        font-size: 20px;
        line-height: 120%;
    }
}
</style>